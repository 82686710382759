import { Button, Grid, Stack, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import DatePicker from "react-date-picker";
// import TableIB from "./TableIB";
import { headerTable } from "functions/Static";
import GridPaperIB from "./GridPaperIB";
import TableIBNest from "./TableIBNest";

const ListIBNest = (props) => {
    const { data, profile, language, page, handleChangePage, state, submitSearch, handleDateStartChange, handleDateEndChange, data_ib_nest, data_header, handleDataNest, getAllIBNest, getIBNest, showAll, handleShowAll } = props;
    const count = Math.ceil(data_ib_nest.length > 0 && data_ib_nest.length / 5);
    const setPage = page - 1;
    const dataList = data_ib_nest.slice(setPage * 5, page * 5);

    return (
        <Grid container rowSpacing={5} justifyContent="center" alignItems="center" mt={5}>
            <Grid item xs={12}>
                <Typography sx={{ fontSize: 24, fontWeight: 600, color: "#000", borderBottom: "solid 2px #4589C6" }}>
                    {language === "th" ? "รายการแผนผัง IB" : "Network IB"}
                </Typography>
                <Stack direction="row" alignItems="center" mt={2}>
                    <Typography sx={{ fontSize: 20, fontWeight: 600, color: "#000", mr: 2, display: { xs: "none", md: "block" } }}>
                        {language === "th" ? "ค้นหา" : "Search"}:
                    </Typography>
                    <Grid container xs={12} md={12} lg={10} sx={{ mr: 2 }} spacing={1}>
                        <Grid item xs={12} md={3}>
                            <DatePicker className={"date-picker"} onChange={handleDateStartChange} value={state.date_start} format="dd/MM/yyyy" />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <DatePicker className={"date-picker"} onChange={handleDateEndChange} value={state.date_end} format="dd/MM/yyyy" />
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <Button
                                variant="contained"
                                style={{ backgroundColor: "#4589C6" }}
                                sx={{ maxWidth: 120, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "none", md: "block" } }}
                                onClick={submitSearch}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        submitSearch();
                                    }
                                }}
                            >
                                {language === "th" ? "ค้นหา" : "Search"}
                            </Button>
                        </Grid>
                    </Grid>
                    <Button
                        variant="contained"
                        color="error"
                        sx={{ maxWidth: 50, fontSize: 16, width: "100%", borderRadius: 40, height: 40, display: { xs: "block", md: "none" } }}
                        onClick={submitSearch}
                    >
                        <SearchIcon />
                    </Button>
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <GridPaperIB data={data_header} header={headerTable.ib_child_list_header} />
            </Grid>
            <Grid item xs={12}>
                <TableIBNest
                    head={headerTable.ib_child_list_nest}
                    data={data}
                    // body={dataList}
                    body={data_ib_nest}
                    count={count}
                    page={page}
                    handleChangePage={handleChangePage}
                    profile={profile}
                    language={language}
                    handleDataNest={handleDataNest}
                    getAllIBNest={getAllIBNest}
                    getIBNest={getIBNest}
                    handleShowAll={handleShowAll}
                    showAll={showAll}
                />
            </Grid>
        </Grid>
    );
};

export default ListIBNest;
